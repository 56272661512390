var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _c("app-header"),
      _c("div", { staticClass: "page-container" }, [
        _c("div", { staticClass: "container" }, [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "spinner-border", attrs: { role: "status" } },
                [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
              )
            : _c("div", { staticClass: "message" }, [_vm._v("Signed Out")])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }