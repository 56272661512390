<template>
  <layout>
    <app-header></app-header>
    <div class="page-container">
      <div class="container">
        <div class="spinner-border" role="status" v-if="loading">
          <span class="sr-only">Loading...</span>
        </div>
        <div v-else class="message">Signed Out</div>
      </div>
    </div>
  </layout>
</template>

<script>
import Header from "../app/components/headers/DefaultHeader.vue";
import Layout from "../app/components/layouts/DefaultLayout.vue";

export default {
  components: {
    appHeader: Header,
    layout: Layout,
  },
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    this.$store.dispatch("logout");
    this.loading = false;
    this.$router.push(`/`);
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  .container {
    max-width: 600px;
    padding: 40px 10px 20px 10px;
    flex: 1;
    .message {
      font-weight: bold;
      font-size: 18px;
    }
  }
}
</style>
